<template lang="pug">
head-meta(
    title-page="Политика конфиденциальности Музея «Шоколадный Выборг»",
    description="Политика защиты персональной информации пользователей сайта Музея «Шоколадный Выборг»"
  )
the-policy
contacts-line
</template>

<script>
import ThePolicy from '../components/policy/ThePolicy'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'PolicyPage',
  components: { ContactsLine, HeadMeta, ThePolicy }
}
</script>
