<template lang="pug">
article.policy
  .policy__cont.cont
    .policy__content
      h1 Политика конфиденциаль&shy;ности
      p
        | Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует
        | в отношении всей информации, которую НАИМЕНОВАНИЕ (далее — Общество) может получить о клиенте или партнере
        | во время использования сайта —&nbsp{{domain}}
      h2 Основные понятия
      p
        | В настоящей Политике конфиденциальности используются следующие термины и понятия:
      p
        | «АДМИНИСТРАЦИЯ {{domain}} (далее — Администрация сайта)»  — уполномоченные сотрудники на управления
        | технологической платформы, действующие от имени Общества, которые организуют и (или)осуществляет обработку
        | персональных данных, а также определяет цели обработки персональных данных, состав персональных данных,
        | подлежащих обработке, действия (операции), совершаемые  с персональными данными.
      p
        | «ПЕРСОНАЛЬНЫЕ ДАННЫЕ» — любая информация, относящаяся к определенному или определяемому на основании такой
        | информации физическому/юридическому лицу, необходимая Обществу в связи с исполнением им договорных обязательств
        | перед Пользователем/Клиентом. Кроме того к персональным данным относятся данные, которые автоматически
        | передаются Сервисам в процессе их использования с помощью установленного на устройстве Пользователя
        | программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере пользователя (или
        | иной программе,с помощью которой осуществляется доступ к Сервисам), время доступа, адрес запрашиваемой страницы
      p
        | «ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ» — сбор, систематизация, накопление,хранение, уточнение (обновление, изменение),
        | использование, распространение (в том числе передача), обезличивание, блокирование, уничтожение персональных
        | данных Клиентов.
      p
        | «ПОЛЬЗОВАТЕЛЬ/КЛИЕНТ» — лицо, которое использует сайт в порядке, регламентированном Администрацией сайта.
      p
        |«КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ» — обязательное для соблюдения работниками Общества, получившими доступ
        |к персональным данным, требование не допускать распространение персональных данных без согласия Клиента или иного
        |законного основания.
      h2 2. Общие положения
      p
        | 2.1. Использование Пользователем/Клиентом сайта {{domain}} означает согласие с настоящей Политикой
        | конфиденциальности и условиями обработки персональных данных Пользователя/ Клиента.
      p
        | 2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь/Клиент должен прекратить
        |использование сайта {{domain}}
      p
        | 2.3. Администрация сайта {{domain}} проверяет достоверность персональных данных, предоставляемых
        | Пользователем сайта.
      p
        | 2.4. Пользователь/Клиент при пользовании Сайтом {{domain}} подтверждает, что:
      ul
        li
          | обладает всеми необходимыми правами, позволяющими ему осуществлять регистрацию (создание учетной записи)
          | и/или использовать услуги сайта {{domain}} без создания учетной записи;
        li
          | указывает достоверную информацию о себе в объемах, необходимых для пользования услугами сайта
          | {{domain}}, обязательные для заполнения поля для дальнейшего предоставления услуг сайта помечены
          | специальным образом, вся иная информация предоставляется Пользователем/Клиентом по его собственному усмотрению;
        li
          | осознает, что информация на сайте, размещаемая Пользователем/Клиентом о себе, может становиться доступной
          | для третьих лиц, не оговоренных в настоящей Политике и может быть скопирована и распространена ими;
          | ознакомлен с настоящей Политикой, выражает свое согласие с ней и принимает на себя указанные в ней права и
          | обязанности. Ознакомление с условиями настоящей Политики, проставление галочки под ссылкой на данную
          | Политику или использование сайта, или отправки данных посредством форм обратной связи на сайте, является
          | письменным согласием Пользователя/Клиента на сбор, хранение, обработку и передачу третьим лицам персональных
          | данных предоставляемых Пользователем/Клиентом.
        p
          | 2.5. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по неразглашению и
          | обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу
          | Администрации сайта при заполнении формы обратной связи на сайте {{domain}}.
        h2 3. Предмет политики конфиденциальности
        p
          | 3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по неразглашению и
          | обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу
          | Администрации сайта при заполнении формы обратной связи на сайте {{domain}}.
        p
          | 3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются
          | Пользователем/Клиентом путём заполнения регистрационной формы на сайте {{domain}}.
        p
          | 3.3. Администрация осуществляет сбор статистики об IP-адресах своих пользователей. Данная информация используется
          | с целью выявления и решения технических проблем, для контроля законности проводимых рекламных компаний и
          | финансовых платежей.
      h2 4. Цели сбора персональной информации пользователя
      p 4.1. Персональные данные Пользователя/Клиента Администрация сайта может использовать в целях:
      ul
        li
          | создания учетной записи и дальнейшей аутентификации и предоставления Пользователю/Клиенту доступа к личному
          | кабинету;
        li
          | установления с Пользователем/Клиентом обратной связи, включая направление уведомлений, запросов, рассылок,
          | смс рассылок, касающихся использования сайта и Владельца;
        li определения места нахождения Пользователя/Клиента для обеспечения безопасности, предотвращения мошенничества;
        li подтверждения достоверности и полноты персональных данных, предоставленных Пользователем/Клиентом;
        li
          | предоставления Пользователю/Клиенту эффективной клиентской и технической поддержки при возникновении проблем
          | связанных с использованием сайта;
          | предоставления Пользователю/Клиенту с его согласия, обновлений сайта/системы, специальных предложений,
          | новостной рассылки и иных сведений от имени сайта.
      h2 5. Способы и сроки обработки персональной информации
      p
        | 5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом,
        | в том числе в информационных системах персональных данных с использованием средств автоматизации или без
        | использования таких средств.
      p
        | 5.2. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате
        | или разглашении персональных данных.
      p
        | 5.3. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной
        | информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования,
        | копирования, распространения, а также от иных неправомерных действий третьих лиц.
      p
        | 5.4. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков
        | или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
      p 5.5. В случае не согласия Пользователя в получении информации от администрации сайта
      p Пользователь может отписаться от рассылки:
      ul
        li кликнув по ссылке «отписаться» внизу письма;
        li в личном кабинете на сайте, путем снятия ранее выбранных опций;
        li путем направления уведомления на электронную почту&nbsp;
          a(:href="mail.link") {{mail.mask}}
      h2 6. Обязательства сторон
      p 6.1. Пользователь обязан:
      ul
        li предоставить актуальную информацию о персональных данных, необходимую для пользования сайтом {{domain}}
        li
          | обновить, дополнить предоставленную администрации сайта информацию о персональных данных в случае изменения
          | данной информации.
      p 6.2. Администрация сайта обязана:
      ul
        li использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности;
        li обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного разрешения Пользователя;
        li
          | принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку,
          | обычно используемого для защиты такого рода информации в существующем деловом обороте.
      h2 7. Ответственность сторон
      p
        | 7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность в соответствии с законодательством
        | Российской Федерации, за исключением случаев, предусмотренных п. п. 5.2. и 7.2. настоящей Политики Конфиденциальности.
      p
        | 7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность,
        | если данная конфиденциальная информация:
      ul
        li стала публичным достоянием до её утраты или разглашения;
        li была получена от третьей стороны до момента её получения Администрацией сайта;
        li была разглашена с согласия Пользователя или самим Пользователем.

      h2 8. Разрешение споров
      p
        | 8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта и Администрацией
        | сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).
      p
        | 8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет
        | заявителя претензии о результатах рассмотрения претензии.
      p
        | 8.3. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется
        | действующее законодательство Российской Федерации.
      h2 9. Дополнительные условия
      p 9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
      p
        | 9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на сайте, если иное не предусмотрено
        | новой редакцией Политики конфиденциальности.
      p
        | 9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать Администрации Сайта
        | на электронный адрес&nbsp;
        a(:href="mail.link") {{mail.mask}}
      p 9.4. Действующая Политика конфиденциальности размещена на странице по адресу https://{{domain}}/policy
      span.policy__date.text-big Дата последнего обновления: 12 августа 2021 года
</template>

<script>
import '@/assets/styles/components/policy.sass'
import { domain, mail } from '../../assets/scripts/variables'

export default {
  name: 'ThePolicy',
  data () {
    return {
      domain,
      mail
    }
  }
}
</script>
